import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUniforms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/uniform-requests', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUniform(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/uniform-requests/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLatestUniform(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/uniform-requests/${userId}/latest-delivered`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUniform(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/uniform-requests/${id}/update`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveUniforms(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/uniform-requests/${id}/approve`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    rejectUniforms(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/uniform-requests/${id}/reject`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUniformAssignments(ctx, { departmentId, gender }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/uniform-assignments', {
            params: { departmentId, gender },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUniformRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/uniform-requests', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
