<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="ShoppingBagIcon"
        size="25"
      />Richieste Divisa da approvare</h1>
    </b-card>
    <!-- Filters -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="text-nowrap"
                @click="openCreateModal"
              >Nuova Richiesta Divisa</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUniformsListTable"
        class="position-relative"
        :items="fetchUniforms"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Richiesta di Divisa da approvare"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(utente)="data">
          <div class="text-nowrap">
            <div class="d-flex align-items-center">
              <b-avatar
                size="25"
                :src="data.item.avatar_file ? mediaUrl + data.item.avatar_file : ''"
                :text="avatarText(data.item.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role_code)}`"
                class="mr-1"
              />
              <b-link>
                #{{ data.item.anagrafico_id }} - {{ data.item.display_name }}
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(reparto)="data">
          <div
            v-if="data.item.department"
            class="text-nowrap"
          >
            <div class="d-flex align-items-center">
              <b-link>
                #{{ data.item.department.id }} - {{ data.item.department.name }}
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(richiestaDaApprovareDel)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ parseDate(data.item.not_delivered_request.created_at) }}</span>
          </div>
        </template>

        <template #cell(ultimaRichiestaConsegnataIl)="data">
          <div
            v-if="data.item.last_delivered_request"
            class="text-nowrap"
          >
            <span class="align-text-top text-capitalize">{{ parseDate(data.item.last_delivered_request.delivered_at) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-manageruniforms-view', params: { id: data.item.not_delivered_request.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUniforms"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="create-modal"
      ref="createModal"
      centered
      size="lg"
      body-class="pt-1 px-1 pb-1"
      footer-class="d-flex justify-content-end"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Crea Nuova Richiesta Divisa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>

      <!-- Corpo della modale -->
      <div class="modal-body p-2">
        <!-- Sezione selezione utente -->
        <b-form-group label="Seleziona Utente">
          <v-select
            v-model="selectedUserId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="users"
            :reduce="val => val.value"
            class="w-100"
            placeholder="Seleziona Utente"
            @input="onUserSelected"
          />
        </b-form-group>

        <div v-if="assignments.length">
          <b-table
            :items="assignments"
            :fields="createFields"
            small
            responsive
            striped
            hover
            head-variant="light"
            class="mt-2"
          >
            <template #cell(uniformName)="data">
              <span class="font-weight-bold text-uppercase">
                {{ data.item.uniformName }}
              </span>
              <b-badge
                v-if="data.item.groupId"
                variant="light-info"
                class="ml-1"
              >
                {{ data.item.groupId }}
              </b-badge>
            </template>

            <template #cell(maxQuantity)="data">
              <span class="badge badge-light-success">{{ data.item.maxQuantity }}</span>
            </template>

            <template #cell(selectedQuantity)="data">
              <b-form-select
                v-model="selectedQuantities[data.item.id]"
                :options="getQuantityOptionsDynamic(data.item)"
                class="w-75"
                @change="onDynamicQuantityChange(data.item)"
              />
            </template>
          </b-table>
        </div>
      </div>

      <!-- Footer personalizzato -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            createUniformRequest()
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAvatar,
  BLink,
  BButton,
  BFormGroup,
  BFormSelect,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/apps/user/userStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useManagerUniformsList from './useManagerUniformsList'
import manageruniformsStoreModule from '../manageruniformsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatar,
    BLink,
    BButton,
    BFormGroup,
    BFormSelect,
    BBadge,
    vSelect,
  },
  setup() {
    const UNIFORMS_APP_STORE_MODULE_NAME = 'app-manageruniforms'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(UNIFORMS_APP_STORE_MODULE_NAME, manageruniformsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(UNIFORMS_APP_STORE_MODULE_NAME)
      }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const loadUsers = () => {
      store.dispatch('app-user/fetchUsers', { per_page: 1000, for_approvation: 1 })
        .then(response => {
          const [userList] = response.data
          const validUsers = userList.filter(u => u.gender && u.department_id)
          users.value = validUsers.map(u => ({
            label: `#${u.anagrafico_id} - ${u.display_name}`,
            value: u.id,
            department_id: u.department_id,
            gender: u.gender,
          }))
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    }
    loadUsers()

    const mediaUrl = process.env.VUE_APP_MEDIA_URL
    const showCreateModal = ref(false)
    const createModal = ref(null)
    const selectedUserId = ref(null)
    const assignments = ref([])
    const selectedQuantities = ref({})

    const createFields = [
      { key: 'uniformName', label: 'Capo' },
      { key: 'maxQuantity', label: 'Max' },
      { key: 'selectedQuantity', label: 'Quantità' },
    ]

    const {
      fetchUniforms,
      tableColumns,
      perPage,
      currentPage,
      totalUniforms,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUniformsListTable,
      parseDate,
      refetchData,
      resolveUserRoleVariant,
    } = useManagerUniformsList()

    const getQuantityOptions = max => {
      const opts = [{ value: 0, text: '0' }]
      for (let i = 1; i <= max; i += 1) {
        opts.push({ value: i, text: i.toString() })
      }
      return opts
    }

    const onUserSelected = userId => {
      const fullUser = users.value.find(u => u.value === userId)
      if (!fullUser) return

      store.dispatch('app-manageruniforms/fetchUniformAssignments', {
        departmentId: fullUser.department_id,
        gender: fullUser.gender,
      })
        .then(response => {
          const [resultArray] = response.data
          assignments.value = resultArray
          selectedQuantities.value = {}
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    }

    const openCreateModal = () => {
      selectedUserId.value = null
      assignments.value = []
      selectedQuantities.value = {}
      showCreateModal.value = true
      if (createModal.value) {
        createModal.value.show()
      }
    }

    const resetModal = () => {
      selectedUserId.value = null
      assignments.value = []
      selectedQuantities.value = {}
    }

    return {
      fetchUniforms,
      tableColumns,
      perPage,
      currentPage,
      totalUniforms,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUniformsListTable,
      parseDate,
      refetchData,
      resolveUserRoleVariant,
      mediaUrl,
      avatarText,
      mainProps: {
        width: 100,
        height: 50,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        width: 100,
        height: 50,
      },
      selectedUniform: null,
      users,
      loadUsers,
      createModal,
      showCreateModal,
      selectedUserId,
      assignments,
      selectedQuantities,
      createFields,
      getQuantityOptions,
      onUserSelected,
      openCreateModal,
      resetModal,
    }
  },
  methods: {
    getQuantityOptionsDynamic(item) {
      const { groupId } = item
      const groupMax = item.maxQuantity

      let sumOfOthers = 0
      this.assignments
        .filter(a => a.groupId === groupId && a.id !== item.id)
        .forEach(a => {
          sumOfOthers += parseInt(this.selectedQuantities[a.id] || 0, 10)
        })

      const remaining = groupMax - sumOfOthers
      const options = []
      for (let i = 0; i <= remaining; i += 1) {
        options.push({ value: i, text: i.toString() })
      }
      return options
    },
    onDynamicQuantityChange(item) {
      const { groupId } = item
      const groupMax = item.maxQuantity
      let sum = 0
      this.assignments
        .filter(a => a.groupId === groupId)
        .forEach(a => {
          sum += parseInt(this.selectedQuantities[a.id] || 0, 10)
        })
      if (sum === groupMax) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Hai raggiunto il limite di ${groupMax} capo/i per questo gruppo`,
            icon: 'ShoppingBagIcon',
            variant: 'info',
          },
        })
      }
    },
    createUniformRequest() {
      if (!this.selectedUserId) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Seleziona un utente per creare la richiesta',
            icon: 'ShoppingBagIcon',
            variant: 'danger',
          },
        })
        return
      }
      const itemsToCreate = []
      Object.keys(this.selectedQuantities).forEach(assignmentId => {
        const q = parseInt(this.selectedQuantities[assignmentId], 10) || 0
        if (q > 0) {
          const found = this.assignments.find(a => a.id === parseInt(assignmentId, 10))
          if (found) {
            itemsToCreate.push({
              uniformId: found.uniformId,
              quantity: q,
            })
          }
        }
      })

      if (itemsToCreate.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Seleziona almeno un capo con quantità > 0',
            icon: 'ShoppingBagIcon',
            variant: 'danger',
          },
        })
        return
      }

      store.dispatch('app-manageruniforms/createUniformRequest', {
        approvatorUserId: this.selectedUserId,
        items: itemsToCreate,
      })
        .then(() => {
          this.$bvModal.hide('create-modal')
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Nuova Richiesta Divisa creata con successo',
              icon: 'ShoppingBagIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Errore nella creazione della richiesta',
              icon: 'ShoppingBagIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vehicle_list_img {
  object-fit: cover;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
