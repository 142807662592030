import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useManagerUniformsList() {
  const refUniformsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'utente', sortable: false },
    { key: 'reparto', sortable: false },
    { key: 'richiestaDaApprovareDel', sortable: false },
    { key: 'ultimaRichiestaConsegnataIl', sortable: false },
    { key: 'azioni' },
  ]
  const fields = {
    utente: 'user',
    reparto: 'department',
    creato: 'created_at',
    approvato: 'approved_at',
  }

  const perPage = ref(10)
  const totalUniforms = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUniformsListTable.value ? refUniformsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUniforms.value,
    }
  })

  const refetchData = () => {
    refUniformsListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchUniforms = (ctx, callback) => {
    store
      .dispatch('app-manageruniforms/fetchUniforms', {
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
      })
      .then(response => {
        const uniforms = response.data[0]
        const total = response.data[1]

        callback(uniforms)
        totalUniforms.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'secondary'
  }

  const parseDate = date => moment(date).format('DD/MM/YYYY')

  return {
    fetchUniforms,
    tableColumns,
    perPage,
    currentPage,
    totalUniforms,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refUniformsListTable,
    parseDate,
    refetchData,
    resolveUserRoleVariant,
  }
}
